import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.scss';

import { SVG } from '../../assets/svg';


import Share from '../share';
import Dropdown from '../dropdown';
import { getLang, getDir } from '../../utils/i18n';
import { getCommunityData } from '../../utils/store';
import langOptions from '../../assets/translations/supported.json';

const Header = (props) => {
	const lang = getLang();

	const data = getCommunityData(props);

	return <header class={style.header} dir={getDir()}>
		<nav>
			<Link href="/"><SVG id='viber-logo-white' /></Link>
		</nav>

		<div class="filler"></div>

		<Share channel={data.channel} />

		<Dropdown defaultVal={lang} clickFn={props.intlChange} options={langOptions}/>
	</header>
};

export default Header;
