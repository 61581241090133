import langOptions from '../assets/translations/supported.json';

export const DEFAULT_LANG = 'en';

export const getLang = () => {
    // get from url
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams && urlParams.has('lang') && Object.keys(langOptions).indexOf(urlParams.get('lang')) >= 0) {
        return urlParams.get('lang');
    }

    // get from browser settings
    const locale = window.navigator.language || window.navigator.userLanguage;
    if (locale) {
        if (Object.keys(langOptions).indexOf(locale) >= 0) {
            return locale;
        } else if (locale.indexOf('-')) {
            const language = locale.split('-')[0];
            if (Object.keys(langOptions).indexOf(language) >= 0) {
                return language;
            }
        }
        
    }

    // return default
    return DEFAULT_LANG;
}

export const getDir = () => {
    const lang = getLang();

    return (['he', 'ar', 'fa'].indexOf(lang) >= 0 ? 'rtl' : 'ltr');
}