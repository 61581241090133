let customMap = {};

export const setCustomDimensions = (dimensions) => {
    customMap = {...dimensions};
}

export const track = (action, data) => {
    const { category, label, value, noninteraction, ...customDimensions } = data;
    const options = {
        ...customMap,
        ...customDimensions
    };
    if (category) {
        options.event_category = category;
    }
    if (label) {
        options.event_label = label;
    }
    if (value) {
        options.value = value;
    }
    if (noninteraction) {
        options.noninteraction = noninteraction;
    }
    window.gtag('event', action, options)
}