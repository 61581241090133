import { h } from 'preact';
import { Router } from 'preact-router';
import { useState } from 'preact/hooks';
import { Provider } from '@preact/prerender-data-provider';
import { useViewport } from '../utils/device';

import style from './app.scss';

import Header from './header';
import { SVGSource } from '../assets/svg';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';


// Import IntlProvider and the definition file.
import { IntlProvider } from 'preact-i18n';
import { getLang, getDir } from '../utils/i18n';
import defaultDefinition from '../assets/translations/locale-en.json';

const App = (props) => {
	const { width } = useViewport();
	const [definition, setDefinition] = useState(defaultDefinition);

	const changeLanguage = (lang) => { 
		// update lang paramereter in url
		const urlParams = new URLSearchParams(window.location.search);
		urlParams.set('lang', lang);
		history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);

		// get the new language translations
		import(`../assets/translations/locale-${lang}.json`)
			.then(definition => setDefinition(definition.default)); 
	  }; 

	const lang = getLang();
	changeLanguage(lang);

	return <Provider value={props}>
		<IntlProvider definition={definition}>
			<SVGSource ></SVGSource>
			<div id="app">
				<Header intlChange={changeLanguage} url="/"/>
				<div id="policy" class={`${style.banner} ${width < 768 ? style.bottom : ''}`} dir={getDir()}></div>
				<Router>
					<Home path="/" />
				</Router>
			</div>
		</IntlProvider>
	</Provider>
}

export default App;
