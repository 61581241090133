import { usePrerenderData } from '@preact/prerender-data-provider';
import config from '../config/config';

// mock data is initialized first, for purpose of debugging
let storeData = config.STORE_MOCK_DATA;

export const getDownloadLink = (lang) => {
    return 'https://www.viber.com/dl/'
}

export const getJoinLink = (hash, members) => {
    hash = encodeURIComponent(hash);
    const cname = members >= 1000 ? hash : 'small_communities';
    return 'https://3p3x.adj.st/?adjust_t=qpcp8mv_yh141mu&adjust_fallback=https%3A%2F%2Fwww.viber.com%2Fdownload&' +
    'adjust_campaign=' + cname + '&adjust_adgroup=defualtlink&adjust_creative=defualtlink&' +
    'adjust_label=' + hash + '&adjust_tracker_limit=200000&adjust_deeplink=viber%3A%2F%2F' +
    'community_invite%3Fdata%3D' + encodeURIComponent(hash) + '%26adjust%3Dtrue%26adjust_t%3Dqpcp8mv_yh141mu%26' +
    'adjust_campaign%3D' + encodeURIComponent(cname) + '%26adjust_adgroup%3Ddefualtlink%26adjust_creative%3Ddefualtlink%26' +
    'adjust_label%3D' + encodeURIComponent(hash) + '%26adjust_tracker_limit%3D200000%26__sourcePage%3Dlanding';
}

export const getCommunityData = (props) => {
    const [ data, loading, error ] = usePrerenderData({ 
        ...props,
        url: props.url.split('?',1)[0] // issue with fetching prerender data when url has query string parameters: https://github.com/preactjs/preact-cli/issues/1455
    });

    if (!loading && !error && (typeof data.id != 'undefined')) {
        storeData = data;
    }

    return storeData;
}