import { h } from 'preact';
import { Link } from 'preact-router/match';
import { useState, useEffect, useRef } from 'preact/hooks';
import { useViewport } from '../../utils/device';
import { getDir } from '../../utils/i18n';
import { SVG } from '../../assets/svg';

import style from './styles.scss';



const Dropdown = (props) => {
	const { width } = useViewport();
	const [opened, setOpened] = useState(false);
	const [selected, setSelected] = useState(props.defaultVal);

	const dropdownRef = useRef(null);
	const listRef = useRef(null);

	const toggleList = () => {
		setOpened(!opened);
	}

	const select = (code) => {
		props.clickFn(code);
		setSelected(code);
		setOpened(false);
	}

	useEffect(() => {
		if (listRef.current) {
			let idx = 0;
			Object.entries(props.options).map( ([code, names], index) => {
				if (code == selected) {
					idx = index;
				}
			});

			listRef.current.scrollTop = idx * 44;
		}


        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpened(false);
            }
        }

        // Bind the event listener
        document.addEventListener('click', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('click', handleClickOutside);
        };
    });

	const renderOpts = Object.entries(props.options).map( ([code, names]) => (
		<li class={code == selected ? style.selected : ''} onClick={() => select(code)} key={code}>
			<span>{names.lang}</span>
		</li>
	));
	
	const renderList = () => (
		<ul class={`${style.list} ${(getDir()=='rtl' ? style.rtl : '')}`} ref={listRef}>
			{renderOpts}
		</ul>
	);

	return (
		<div class={style.dropdown} ref={dropdownRef}>
			<div class={style.trigger} onClick={toggleList}>
				{width > 768 ? props.options[selected].lang : props.options[selected].short.toUpperCase()}
				<span class={style.arrow}></span>
			</div>
			{ opened ? renderList() : null }
		</div>
	);
}

export default Dropdown;
