export const SITE_URL = 'https://invite.viber.com';
export const PGWS_HOST = 'pg.cdn.viber.com';
export const SHARE_HOST = 'share.cdn.viber.com';
export const WA_HOST = 'wa.viber.com';
export const STORE_MOCK_DATA = {
    url: '/',
	hash: '',
	id: 0,
    name: 'Community Landing Page',
    icon: '/public/static/assets/community_default_icon.png',
    description: '',
    members: 0,
    open: false,
    verified: false,
    channel: false
}
export const GA_ACCOUNT = 'UA-18303830-38';