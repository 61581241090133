import { useState, useEffect } from 'preact/hooks';
import * as MobileDetect from 'mobile-detect';

const md = new MobileDetect(window.navigator.userAgent);

export const isMobile = () => !!md.mobile();

export const isIOS = () => md.os() === 'iOS';

export const useViewport = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return { width };
}