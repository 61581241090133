import { h } from "preact";
import { Link } from "preact-router/match";
import { useState } from "preact/hooks";

import { track } from "../../utils/track";

import style from "./style.scss";
import config from "../../config/config";
import { useViewport, isIOS } from "../../utils/device";
import { getDir } from "../../utils/i18n";
import { Text } from "preact-i18n";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { SVG } from "../../assets/svg";

const Share = (props) => {
  const { width } = useViewport();
  const [opened, setOpened] = useState(false);
  const [copied, setCopied] = useState(false);

  const getIcon = (type) => {
    return `${type}${width < 768 ? "-mobile" : ""}`;
  };

  const triggerClass = () => {
    return `${style.shareTrigger} ${opened ? style.highlight : ""} ${
      isIOS() ? style.ios : ""
    }`;
  };

  const copyClipboardSuccess = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const toggleIcons = () => {
    setOpened(!opened);
  };

  const trackShare = (label) => {
    track("Share", {
      shareType: props.channel ? "Channel" : "Community",
      category: "click",
      label,
    });
  };

  return (
    <>
      <div class={triggerClass()} onClick={() => toggleIcons()}>
        <SVG id={`share-header${isIOS() ? "-ios" : ""}`} />
      </div>
      <div class={style.shareButtons}>
        <ul
          class={`${opened ? style.open : ""} ${
            getDir() == "rtl" ? style.rtl : ""
          }`}
        >
          <li onClick={() => trackShare("FB")}>
            <a
              target="_blank"
              href={config.SHARE.FACEBOOK(window.location.href)}
            >
              <SVG id={getIcon("fb")} />
            </a>
          </li>
          <li onClick={() => trackShare("Twitter")}>
            <a
              target="_blank"
              href={config.SHARE.TWITTER(window.location.href)}
            >
              <SVG id={getIcon("twitter")} />
            </a>
          </li>
          <li onClick={() => trackShare("Copy link")}>
            <CopyToClipboard
              text={window.location}
              onCopy={() => copyClipboardSuccess()}
            >
              <a href="javascript:void(0);">
                <SVG id={getIcon("copy")} />
              </a>
            </CopyToClipboard>
          </li>
          {copied && (
            <div class={style.snackbar}>
              <Text id="copy-message">Copied to clipboard</Text>
            </div>
          )}
        </ul>
      </div>
    </>
  );
};

export default Share;
