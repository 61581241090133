import {
  SITE_URL,
  PGWS_HOST,
  SHARE_HOST,
  WA_HOST,
  STORE_MOCK_DATA,
  GA_ACCOUNT,
} from "./config.dev";

const COMMUNITY_DATA = "/pgws/get_info_discover?inviteLink=";
const COMMUNITY_FALLBACK_DATA = "/vbe/group2_details?inviteLink=";

export default {
  SITE_URL,
  COMMUNITY_DATA: (hash) => `https://${PGWS_HOST}${COMMUNITY_DATA}${hash}`,
  COMMUNITY_FALLBACK_DATA: (hash) =>
    `https://${WA_HOST}${COMMUNITY_FALLBACK_DATA}${hash}`,
  ICON_URL: (icn) =>
    `https://${SHARE_HOST}/pg_download?id=${icn}&filetype=jpg&type=icon`,
  DEFAULT_ICON_URL: "/public/static/assets/community_default_icon.png",
  SHARE: {
    TWITTER: (url) =>
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`,
    FACEBOOK: (url) =>
      `https://www.facebook.com/sharer/sharer.php?app_id=269907439854556&u=${encodeURIComponent(
        url
      )}` + "&display=popup&ref=plugin",
  },
  STORE_MOCK_DATA,
  GA_ACCOUNT,
};
